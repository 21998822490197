<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div class="d-flex">
    <a
      v-for="platform in platforms"
      :href="getUrl(platform.value)"
      :key="platform.label"
      target="_blank"
    >
      <img width="35" height="35" class="mr-5" :alt="platform.label" :src="platform.photo" />
    </a>
  </div>
</template>

<script lang="babel" type="text/babel">
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import shareMixin from './share.js'
export default {
  mixins: [shareMixin],
  methods: {
    getUrl(platform) {
      if(platform === 'facebook') {
        return this.facebookShareUrl
      }
      if(platform === 'twitter') {
        return this.twitterShareUrl
      }
      if(platform === 'line') {
        return this.lineShareUrl
      }
      if(platform === 'linkedIn') {
        return this.linkedInShareUrl
      }
    },
  },
  computed: {
    isDark() {
      return this.$store.getters['base/isDark']
    },
    platforms() {
      return [
        {
          label: 'Facebook',
          value: 'facebook',
          photo: require('@/assets/img/icon/fb-dark.svg')
        },
        // {
        //   label: 'Twitter',
        //   value: 'twitter',
        //   photo: require('@/assets/img/icon/ig-dark.png')
        // },
        {
          label: 'LinkedIn',
          value: 'linkedIn',
          photo: require('@/assets/img/icon/linkin-dark.svg')
        },
        {
          label: 'Line',
          value: 'line',
          photo: require('@/assets/img/icon/line-dark.svg'),
        },
      ]
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>